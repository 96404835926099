/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import DeleteTopicView from './DeleteTopicView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('DeleteTopic');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		topic:		state.topic.topic
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		deleteTopic: (id) => {dispatch(actions.deleteTopic(id));}
	};
};

const DeleteTopic = connect(mapStateToProps, mapDispatchToProps)(DeleteTopicView);

export default DeleteTopic;
