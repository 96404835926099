/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import TopicFormView from './TopicFormView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('TopicForm');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		isworking:	state.topic.isworking,
		topics:		state.topic.topics
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		getTopics: () => {dispatch(actions.getTopics());},
		getTopic: (id) => {dispatch(actions.getTopic(id));},
		deleteTopic: (id) => {dispatch(actions.deleteTopic(id));}
	};
};

const TopicForm = connect(mapStateToProps, mapDispatchToProps)(TopicFormView);

export default TopicForm;
