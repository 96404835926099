/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import FeedFormView from './FeedFormView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('FeedForm');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		isworking:	state.feed.isworking,
		feeds:		state.feed.feeds
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		getFeeds: () => {dispatch(actions.getFeeds());},
		getFeed: (id) => {dispatch(actions.getFeed(id));},
		deleteFeed: (id) => {dispatch(actions.deleteFeed(id));}
	};
};

const FeedForm = connect(mapStateToProps, mapDispatchToProps)(FeedFormView);

export default FeedForm;
