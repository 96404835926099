/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import fetch from 'cross-fetch';
import { coreInit, fetchOk } from './request_utils';
import Debug from 'debug';

let debug = Debug('test_requests');


export const runTest = () => {
	debug('runTest, called.');

	let init = {
		credentials:	'same-origin',
		method:			'POST'
	};

	debug('runTest, init is: ' + JSON.stringify(init));
	return fetchOk('/test/run', init)
		.then((response) => {debug('runTest, fetch ok.');return response;})
		.catch((error) => {debug('runTest, fetch, caught err.'); throw error;});

};

export const emailTest = (email) => {
	debug('emailTest, called.');
	debug('emailTest, email: ' + email);
	const formData = new FormData();
	formData.append('email', email);

	let init = {
		credentials:	'same-origin',
		method:			'POST',
		body:			formData
	};

	debug('emailTest, init is: ' + JSON.stringify(init));
	return fetchOk('/test/email', init)
		.then((response) => {debug('emailTest, fetch ok.');return response;})
		.catch((error) => {debug('emailTest, fetch, caught err.'); throw error;});

};
