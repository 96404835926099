/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels

 */
// Auth Action Types
export const AUTHENTICATE = 'AUTHENTICATE';
export const GET_USER = 'GET_USER';
export const LOGOUT = 'LOGOUT';

// Profile Action Types
export const CREATE_PROFILE = 'CREATE_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_SUB = 'UPDATE_SUB';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const DELETE_PROFILE = 'DELETE_PROFILE';


// Article Action Types
export const GET_ARTICLES = 'GET_ARTICLES';
export const GET_ARTICLE = 'GET_ARTICLE';
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';

// Feed Action Types
export const CREATE_FEED = 'CREATE_FEED';
export const GET_FEEDS = 'GET_FEEDS';
export const GET_FEED = 'GET_FEED';
export const UPDATE_FEED = 'UPDATE_FEED';
export const DELETE_FEED = 'DELETE_FEED';

// Topic Action Types
export const CREATE_TOPIC = 'CREATE_TOPIC';
export const GET_TOPICS = 'GET_TOPICS';
export const GET_TOPIC = 'GET_TOPIC';
export const UPDATE_TOPIC = 'UPDATE_TOPIC';
export const DELETE_TOPIC = 'DELETE_TOPIC';

// LLM Action Types
export const LLM_ANALYZE = 'LLM_ANALYZE';

// Test Action Types
export const RUN_TEST = 'RUN_TEST';
export const EMAIL_TEST = 'EMAIL_TEST';
