/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../shared/ErrorBoundary';
import Debug from 'debug';

let debug = Debug('UpdateFeedView');

export class UpdateFeedView extends Component {

	handleUpdate() {
		debug('handleUpdate, called.');
		debug('handleUpdate, update-zh-ok: ' + document.querySelector('#update-zh-ok').checked);

		if (this.props.feed) {
	    	this.props.updateFeed(
				this.props.feed._id,
				document.querySelector('#update-name').value,
				document.querySelector('#update-url').value,
				document.querySelector('#update-zh-ok').checked
			);
		}
	}


	renderBody() {
		debug('renderBody, called.');
		if (this.props.feed) {
			return <div className="modal-body">
				<div className="mb-3">
					<label htmlFor="update-name" className="form-label">Name</label>
					<input className="form-control" id="update-name" defaultValue={this.props.feed.name} aria-label="feed name" placeholder="Name that will appear on the list." required></input>
				</div>
				<div className="mb-3">
					<label htmlFor="update-url" className="form-label">RSS URL</label>
					<input type="url" className="form-control" id="update-url" defaultValue={this.props.feed.url} aria-label="feed url" placeholder="The URL where the RSS feed can be found." required></input>
				</div>
				<div className="mb-3">
					<input className="form-check-input" type="checkbox" id="update-zh-ok" defaultChecked={this.props.feed.zh_ok}/>
					&nbsp;<label htmlFor="update-zh-ok" className="form-label">Ok in China?</label>
					<p><small className="text-body-secondary">This indicates if the website is accessable from China, you can check here: http://www.chinafirewalltest.com/</small></p>
				</div>
			</div>;
		} else {
			return <div className="modal-body">
			</div>;
		}
	};


	render () {
		debug('render, called.');

		return <div className="modal fade" id="update-feed-modal" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="feed-title" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="feed-title">Feed Dialog</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<ErrorBoundary>{this.renderBody()}</ErrorBoundary>
					<div className="modal-footer">
						<button type="button" className="btn btn-outline-primary" onClick={this.handleUpdate.bind(this)} data-bs-dismiss="modal">Update</button>
						<button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>;
	}
}


UpdateFeedView.propTypes = {
	feed:			PropTypes.object,
	updateFeed:		PropTypes.func
};


export default UpdateFeedView;
