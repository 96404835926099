/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import DeleteUserView from './DeleteUserView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('DeleteUser');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		profile:		state.profile.profile
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		deleteProfile: (id) => {dispatch(actions.deleteProfile(id));}
	};
};

const DeleteUser = connect(mapStateToProps, mapDispatchToProps)(DeleteUserView);

export default DeleteUser;
