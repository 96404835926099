/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import DeleteFeedView from './DeleteFeedView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('DeleteFeed');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		feed:		state.feed.feed
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		deleteFeed: (id) => {dispatch(actions.deleteFeed(id));}
	};
};

const DeleteFeed = connect(mapStateToProps, mapDispatchToProps)(DeleteFeedView);

export default DeleteFeed;
