/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal } from 'bootstrap';
import ErrorBoundary from '../../shared/ErrorBoundary';
import AddTopic from '../modals/AddTopic';
import UpdateTopic from '../modals/UpdateTopic';
import DeleteTopic from '../modals/DeleteTopic';
import { showMessage } from '../../shared/MessageToast';
import { Toast } from 'bootstrap';
import Debug from 'debug';

let debug = Debug('TopicFormView');


class TopicFormView extends Component {

	shouldComponentUpdate() {
		debug('shouldComponentUpdate, called.');
		return this.props.isworking;
	}

	componentDidMount() {
		debug('componentDidMount, called.');
		this.props.getTopics();
	}

	buildTopicList() {
		debug('buildTopicList, called.');
		return this.props.topics.map(topic => <option value={topic._id} key={topic._id}>{topic.title}</option>);
	}

	handleAdd() {
		debug('handleAdd, called.');
		const topicModal = new Modal('#add-topic-modal');
		topicModal.show();
	}

	handleUpdate() {
		debug('handleUpdate, called.');
		var topicList = document.getElementById('topic-list');

		if (topicList.selectedIndex != -1) {
			this.props.getTopic(topicList.options[topicList.selectedIndex].value);

			const topicModal = new Modal('#update-topic-modal');
			topicModal.show();
		} else {
			showMessage('You must select a topic to update.');
		}
	}

	handleDelete() {
		debug('handleDelete, called.');
		var topicList = document.getElementById('topic-list');

		if (topicList.selectedIndex != -1) {
			this.props.getTopic(topicList.options[topicList.selectedIndex].value);

			const topicModal = new Modal('#delete-topic-modal');
			topicModal.show();
		} else {
			showMessage('You must select a topic to delete.');
		}

	}



  	render () {
    	debug('render, called.');

		return 	<form>
			<div>
				<div>
					<label htmlFor="topic-list" className="form-label">Current topics</label>
					<select className="form-select" id="topic-list" size="5" aria-label="RSS topic selection" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-classname="custom-tooltip" data-bs-title="A list of all the topics.">
						<ErrorBoundary>{this.buildTopicList()}</ErrorBoundary>
					</select>
				</div>
				<div>
					<div className="btn-group list-buttons" role="group">
						<button type="button" className="btn btn-primary" onClick={this.handleAdd.bind(this)}><i className="bi bi-plus-lg"></i></button>
						<button type="button" className="btn btn-primary" onClick={this.handleUpdate.bind(this)}><i className="bi bi-pencil-square"></i></button>
						<button type="button" className="btn btn-primary" onClick={this.handleDelete.bind(this)}><i className="bi bi-dash"></i></button>
					</div>
				</div>
				<AddTopic/>
				<UpdateTopic/>
				<DeleteTopic/>
			</div>
		</form>;
	}
};

TopicFormView.propTypes = {
	isworking:		PropTypes.bool,
	getTopic: 		PropTypes.func,
	getTopics: 		PropTypes.func,
	topics:			PropTypes.array
};

export default TopicFormView;
