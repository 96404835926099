/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../shared/ErrorBoundary';
import Debug from 'debug';

let debug = Debug('AddTopicView');

export class AddTopicView extends Component {

	handleAdd() {
		debug('handleUpdate, called.');

		this.props.createTopic(
			document.querySelector('#add-title').value
		);

		document.querySelector('#add-title').value = '';
	}


	render () {
		debug('render, called.');

		return <div className="modal fade" id="add-topic-modal" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="topic-title" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="topic-title">Add Topic Dialog</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<div className="mb-3">
							<label htmlFor="add-title" className="form-label">Title</label>
							<input className="form-control" id="add-title" aria-label="topic title" placeholder="Topic title that will be used to find news of interest." required></input>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-outline-primary" onClick={this.handleAdd.bind(this)} data-bs-dismiss="modal">Add</button>
						<button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>;
	}
}


AddTopicView.propTypes = {
	createTopic:	PropTypes.func
};


export default AddTopicView;
