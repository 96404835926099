/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import fetch from 'cross-fetch';
import { coreInit, fetchOk } from './request_utils';
import Debug from 'debug';

let debug = Debug('auth_requests');


export const authenticate = () => {
	debug('authenticate, called.');

	let init = {
		...coreInit,
		method:			'GET'
	};

	debug('authenticate, init is: ' + JSON.stringify(init));
	return fetchOk('/authenticate', init)
		.then((response) => {debug('validate, fetch ok.');return response;})
		.catch((error) => {debug('validate, fetch, caught err.'); throw error;});

};


export const getUser = () => {
	debug('getUser, called.');

	let init = {
		...coreInit,
		method:			'GET'
	};

	debug('getUser, init is: ' + JSON.stringify(init));
	return fetchOk('/auth/user', init)
		.then((response) => {debug('getUser, fetch ok.');return response;})
		.catch((error) => {debug('getUser, fetch, caught err.'); throw error;});

};


export const logout = () => {
	debug('logout, called.');

	let init = {
		...coreInit,
		method:			'GET'
	};

	return fetchOk('/logout', init)
		.then((response) => {debug('logout, fetch ok.');return response;})
		.catch((error) => {debug('logout, fetch, caught err.'); throw error;});
};
