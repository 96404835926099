/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import fetch from 'cross-fetch';
import { coreInit, fetchOk, appendObjectArrayToFormData } from './request_utils';
import Debug from 'debug';

let debug = Debug('article_requests');

export const getArticles = (newOnly) => {
	debug('getArticles, called.');

	let init = {
		...coreInit,
		method:			'GET'
	};

	debug('getArticles, init is: ' + JSON.stringify(init));
	return fetchOk('/articles/' + newOnly, init)
		.then((response) => {debug('getArticles, response ok.');return response;})
		.catch((error) => {debug('getArticles, fetch, caught err.'); throw error;});

};

export const getArticle = (id) => {
	debug('getArticle, called.');

	let init = {
		...coreInit,
		method:			'GET'
	};

	debug('getArticle, init is: ' + JSON.stringify(init));
	return fetchOk('/article/' + id, init)
		.then((response) => {debug('getArticle, response ok.');return response;})
		.catch((error) => {debug('getArticle, fetch, caught err.'); throw error;});

};

export const updateArticle = (id, title, author, url, date, content, summary, topics, rating, source, sourceUrl) => {
	debug('updateArticle, called.');
	debug('updateArticle, adding: ' + id + ', ' + title + ', ' + author+ ', ' + url + ', ' + date + ', ' + topics + ', ' + rating + ', ' + source);
	const formData = new FormData();
	formData.append('title', title);
	formData.append('author', author);
	formData.append('url', url);
	formData.append('date', date);
	formData.append('content', content);
	formData.append('summary', summary);
	appendObjectArrayToFormData(topics, 'topics', formData);
	/*topics.forEach((topic, index) => {
    	Object.keys(topic).forEach((key) => {
      		const value = topic[key];
      		const fieldKey = `topics[${index}][${key}]`;
      		formData.append(fieldKey, value);
    	});
  	});*/
	formData.append('rating', rating);
	formData.append('source', source);
	formData.append('sourceUrl', sourceUrl);

	let init = {
		credentials:	'same-origin',
		method:			'PUT',
		body:			formData
	};

	debug('updateArticle, init is: ' + JSON.stringify(init));
	return fetchOk('/article/' + id, init)
		.then((response) => {debug('updateArticle, response ok.');return response;})
		.catch((error) => {debug('updateArticle, fetch, caught err.'); throw error;});

};

export const deleteArticle = (id) => {
	debug('deleteArticle, called.');

	let init = {
		...coreInit,
		method:			'DELETE',
		body:			JSON.stringify({'id': id})
	};

	debug('deleteArticle, init is: ' + JSON.stringify(init));
	return fetchOk('/article/' + id, init)
		.then((response) => {debug('deleteArticle, response ok.');return response;})
		.catch((error) => {debug('deleteArticle, fetch, caught err.'); throw error;});

};
