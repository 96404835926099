/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal } from 'bootstrap';
import ErrorBoundary from '../../shared/ErrorBoundary';
import { showMessage } from '../../shared/MessageToast';
import { Toast } from 'bootstrap';
import Debug from 'debug';

let debug = Debug('TestFormView');


class TestFormView extends Component {

	handleTest() {
		debug('handleTest, called.');
		this.props.runTest();
	}

	handleEmail() {
		debug('handleEmail, called.');
		this.props.emailTest(document.querySelector('#test-email').value);
	}

  	render () {
    	debug('render, called.');

		return 	<form>
			<div>
				<div className="d-grid gap-2">
					<button className="btn btn-danger" type="button" onClick={this.handleTest.bind(this)}>Test Full Run</button>
				</div>
				<p><small className="text-body-secondary">Warning: This will re-run news gathering and will email all readers again.</small></p>
				<br />
				<div className="input-group mb-3">
					<input type="email" class="form-control" id="test-email" placeholder="Test email address" aria-label="Test email address" aria-describedby="send-button" />
  					<button className="btn btn-outline-secondary" id="send-button" type="button" onClick={this.handleEmail.bind(this)}>Send</button>
				</div>
				<p><small className="text-body-secondary">Sends a test email to the provided address.</small></p>
			</div>
		</form>;
	}
};

TestFormView.propTypes = {
	runTest:		PropTypes.func,
	emailTest:		PropTypes.func
};

export default TestFormView;
