/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import UpdateExtUserView from './UpdateExtUserView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('UpdateExtUser');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		profile:		state.profile.profile
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		updateProfile: (id, givenName, familyName, email, zh) => {dispatch(actions.updateProfile(id, givenName, familyName, email, zh));}
	};
};

const UpdateExtUser = connect(mapStateToProps, mapDispatchToProps)(UpdateExtUserView);

export default UpdateExtUser;
