/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import UpdateFeedView from './UpdateFeedView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('UpdateFeed');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		feed:		state.feed.feed
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		updateFeed: (id, name, url, zh_ok) => {dispatch(actions.updateFeed(id, name, url, zh_ok));}
	};
};

const UpdateFeed = connect(mapStateToProps, mapDispatchToProps)(UpdateFeedView);

export default UpdateFeed;
