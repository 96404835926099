/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import * as index from '../index';
import * as actions from './action_types';
import * as requests from '../requests';

export const authenticate = () => ({ type: actions.AUTHENTICATE, promise: requests.authenticate()});
export const getUser = () => ({type: actions.GET_USER, promise: requests.getUser()});
export const logout = () => ({ type: actions.LOGOUT, promise: requests.logout()});
