/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';
import memoize from 'memoize-one';
import ReactStars from 'react-rating-stars-component';
import { Modal } from 'bootstrap';
import ErrorBoundary from '../shared/ErrorBoundary';
import Article from './modals/Article';
import Analysis from './modals/Analysis';
import Main from '../Main';
import { ADMIN } from '../shared/constants';
import Debug from 'debug';
import moment from 'moment';

let debug = Debug('NewsView');

const columns = memoize(handleClick => [
	{
		name: 'Title',
		selector: row => row.title,
		grow: 4,
		sortable: true
	},
	{
		name: 'New',
		selector: row => row.new,
		format: row => row.new ? <i class="bi bi-check-circle text-success"></i> : <i class="bi bi-x-circle text-secondary"></i>
	},
	{
		name: 'Source',
		selector: row => row.source,
		sortable: true
	},
	{
		name: 'Date',
		selector: row => row.date,
		format: row => moment(row.date).format('MMM Do, YYYY'),
		sortable: true,
		sortFunction: sortDate
	},
	{
		name: 'Summary Rating',
		selector: row => row.rating,
		format: row => <ReactStars count={5} emptyIcon={<i className='bi bi-star'></i>} halfIcon={<i className='bi bi-star-half'></i>} fullIcon={<i className='bi bi-star-fill'></i>} edit={false} isHalf={false} value={row.rating} />,
		sortable: true
	},
	{
		name: 'Create Analysis',
		button: true,
		cell: row => <button type="button" className="btn btn-outline-secondary btn-sm" onClick={handleClick} id={row._id}>Analyze</button>,
		ignoreRowClick: true
	}

]);


const sortDate = (row1, row2) => {
	let result = 0;

	if (moment(row1.date).isAfter(moment(row2.date))) {result = 1;}
	if (moment(row2.date).isAfter(moment(row1.date))) {result = -1;}

	return result;
};


class NewsView extends Component {

	shouldComponentUpdate() {
		debug('shouldComponentUpdate, called.');
		return this.props.isworking;
	}

	componentDidMount() {
		debug('componentDidMount, called.');
		this.props.getArticles(true);
	}


	handleNewOnlyFilter() {
		debug('handleNewOnlyFilter, called.');
		this.props.getArticles(document.querySelector('#newOnly').checked);
	}

	handleSelection(row) {
		debug('handleSelection, called.');
		this.props.getArticle(row._id);

		const articleModal = new Modal('#article-modal');
		articleModal.show();
	}


	handleAnalysis(event) {
		debug('handleAnalysis, called.');
		this.props.getAnalysis(event.target.id);

		const analysisModal = new Modal('#analysis-modal');
		analysisModal.show();
	}


  	render () {
    	debug('render, called.');
		//if (this.props.articles) {
		return 	<Main>
			<div className="row  align-items-center">
				<div className="col">
					<h4>Articles</h4>
				</div>
			</div>
			<hr className="my-3"/>
			<div className="form-check form-switch">
				<input className="form-check-input" type="checkbox" role="switch" id="newOnly" defaultChecked={true} onClick={this.handleNewOnlyFilter.bind(this)}/>
				<label className="form-check-label" forhtml="newOnly">New articles only</label>
			</div>
			<ErrorBoundary>
				<DataTable columns={columns(this.handleAnalysis.bind(this))} data={this.props.articles} keyField='_id' striped onRowClicked={this.handleSelection.bind(this)} defaultSortFieldId={4} defaultSortAsc={false} pagination paginationPerPage={20}/>
			</ErrorBoundary>
			<ErrorBoundary><Article /></ErrorBoundary>
			<ErrorBoundary><Analysis /></ErrorBoundary>
		</Main>;
	}
};


NewsView.propTypes = {
	isworking:		PropTypes.bool,
	getArticle: 	PropTypes.func,
	getArticles: 	PropTypes.func,
	getAnalysis: 	PropTypes.func,
	articles:		PropTypes.array,
	user:			PropTypes.object
};

export default NewsView;
