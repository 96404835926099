/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import Debug from 'debug';
import Main from '../Main';
import ErrorBoundary from '../shared/ErrorBoundary';

let debug = Debug('ProfileView');

class ProfileView extends Component {

	componentDidMount() {
		debug('componentDidUpdate.');
		// Get the session User
		this.props.getProfile(this.props.user._id);
	}

	handleUpdate() {
		debug('handleUpdate, called.');

		if (this.props.profile) {
	    	this.props.updateSub(
				this.props.profile._id,
				document.querySelector('#summary-sub').checked
			);
		}
	}

	render () {
		debug('render, called.');
		if (this.props.profile) {
			return 	<Main>
				<h4>Profile</h4>
				<div className="container">
					<div className="row align-items-start">
						<div className="col-md-3"></div>
						<div className="col-md-6">
							<div className="mb-3">
								<label htmlFor="first-name" className="form-label">First Name</label>
								<input className="form-control" id="first-name" defaultValue={this.props.profile.givenName} aria-label="subscribers first name" placeholder="The subscribers first name." disabled readonly></input>
							</div>
							<div className="mb-3">
								<label htmlFor="last-name" className="form-label">Last Name</label>
								<input className="form-control" id="last-name" defaultValue={this.props.profile.familyName} aria-label="subscribers last name" placeholder="The subscribers last name." disabled readonly></input>
							</div>
							<div className="mb-3">
								<label htmlFor="email" className="form-label">E-mail</label>
								<input type="email" className="form-control" id="email" defaultValue={this.props.profile.email} aria-label="subscribers email" placeholder="The subscribers email." disabled readonly></input>
							</div>
							<div className="mb-3">
								<div className="form-check form-switch">
									<input className="form-check-input" type="checkbox" role="switch" id="summary-sub" defaultChecked={this.props.profile.summarySub}/>
									<label className="form-check-label" forHtml="summary-sub">Subscribe to news summaries.</label>
								</div>
							</div>
							<button type="button" className="btn btn-primary" onClick={this.handleUpdate.bind(this)} data-bs-dismiss="modal">Update Subscriptions</button>
						</div>
						<div className="col-md-3"></div>
					</div>
				</div>
			</Main>;
		} else {
			return null;
		}

	}
};



ProfileView.propTypes = {
	user:			PropTypes.object,
	profile:		PropTypes.object,
	getProfile:		PropTypes.func,
	updateSub:		PropTypes.func
};


export default ProfileView;
