/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import fetch from 'cross-fetch';
import { coreInit, fetchOk } from './request_utils';
import Debug from 'debug';

let debug = Debug('llm_requests');

export const getAnalysis = (id) => {
	debug('getAnalysis, called.');

	let init = {
		...coreInit,
		method:			'GET'
	};

	debug('getAnalysis, init is: ' + JSON.stringify(init));
	return fetchOk('/llm/analysis/' + id, init)
		.then((response) => {debug('getAnalysis, response ok.');return response;})
		.catch((error) => {debug('getAnalysis, fetch, caught err.'); throw error;});

};
