/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import fetch from 'cross-fetch';
import { coreInit, fetchOk } from './request_utils';
import Debug from 'debug';

let debug = Debug('feed_requests');

export const createFeed = (name, url, zh_ok) => {
	debug('createFeed, called.');
	debug('createFeed, adding: ' + name + ', ' + url + ', ' + zh_ok);
	const formData = new FormData();
	formData.append('name', name);
	formData.append('url', url);
	formData.append('zh_ok', zh_ok);

	let init = {
		credentials:	'same-origin',
		method:			'POST',
		body:			formData
	};

	debug('createFeed, init is: ' + JSON.stringify(init));
	return fetchOk('/feed', init)
		.then((response) => {debug('createFeed, response ok.');return response;})
		.catch((error) => {debug('createFeed, fetch, caught err.'); throw error;});

};

export const getFeeds = () => {
	debug('getFeeds, called.');

	let init = {
		...coreInit,
		method:			'GET'
	};

	debug('getFeeds, init is: ' + JSON.stringify(init));
	return fetchOk('/feeds', init)
		.then((response) => {debug('getFeeds, response ok.');return response;})
		.catch((error) => {debug('getFeeds, fetch, caught err.'); throw error;});

};

export const getFeed = (id) => {
	debug('getFeed, called.');

	let init = {
		...coreInit,
		method:			'GET'
	};

	debug('getFeed, init is: ' + JSON.stringify(init));
	return fetchOk('/feed/' + id, init)
		.then((response) => {debug('getFeed, response ok.');return response;})
		.catch((error) => {debug('getFeed, fetch, caught err.'); throw error;});

};

export const updateFeed = (id, name, url, zh_ok) => {
	debug('updateFeed, called.');
	debug('updateFeed, adding: ' + id + ', ' + name + ', ' + url + ', ' + zh_ok);
	const formData = new FormData();
	formData.append('name', name);
	formData.append('url', url);
	formData.append('zh_ok', zh_ok);

	let init = {
		credentials:	'same-origin',
		method:			'PUT',
		body:			formData
	};

	debug('updateFeed, init is: ' + JSON.stringify(init));
	return fetchOk('/feed/' + id, init)
		.then((response) => {debug('updateFeed, response ok.');return response;})
		.catch((error) => {debug('updateFeed, fetch, caught err.'); throw error;});

};

export const deleteFeed = (id) => {
	debug('deleteFeed, called.');

	let init = {
		...coreInit,
		method:			'DELETE',
		body:			JSON.stringify({'id': id})
	};

	debug('deleteFeed, init is: ' + JSON.stringify(init));
	return fetchOk('/feed/' + id, init)
		.then((response) => {debug('deleteFeed, response ok.');return response;})
		.catch((error) => {debug('deleteFeed, fetch, caught err.'); throw error;});

};
