/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import AnalysisView from './AnalysisView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('Analysis');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	debug('State: ' + JSON.stringify(state.llm));
	return {
		analysis:	state.llm.analysis
	};
};

/*const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		updateArticle: (id, title, author, url, date, content, summary, topics, rating, source, sourceUrl) => {dispatch(actions.updateArticle(id, title, author, url, date, content, summary, topics, rating, source, sourceUrl));},
		deleteArticle: (id) => {dispatch(actions.deleteArticle(id));}
	};
};*/

const Analysis = connect(mapStateToProps, null)(AnalysisView);

export default Analysis;
