/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import ArticleView from './ArticleView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('Article');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		isworking:	state.article.isworking,
		article:	state.article.article
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		updateArticle: (id, title, author, url, date, content, summary, topics, rating, source, sourceUrl) => {dispatch(actions.updateArticle(id, title, author, url, date, content, summary, topics, rating, source, sourceUrl));},
		deleteArticle: (id) => {dispatch(actions.deleteArticle(id));}
	};
};

const Article = connect(mapStateToProps, mapDispatchToProps)(ArticleView);

export default Article;
