/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import AddExtUserView from './AddExtUserView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('AddExtUser');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		profile:		state.profile.profile
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		createProfile: (givenName, familyName, email, zh) => {dispatch(actions.createProfile(givenName, familyName, email, zh));}
	};
};

const AddExtUser = connect(mapStateToProps, mapDispatchToProps)(AddExtUserView);

export default AddExtUser;
