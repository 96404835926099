/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import fetch from 'cross-fetch';
import { coreInit, fetchOk } from './request_utils';
import Debug from 'debug';

let debug = Debug('profile_requests');

export const createProfile = (givenName, familyName, email, zh) => {
	debug('createProfile, called.');
	debug('createProfile, adding: ' + givenName + ', ' + familyName + ', ' + email + ', ' + zh);
	const formData = new FormData();
	formData.append('givenName', givenName);
	formData.append('familyName', familyName);
	formData.append('email', email);
	formData.append('zh', zh);

	let init = {
		credentials:	'same-origin',
		method:			'POST',
		body:			formData
	};

	debug('createProfile, init is: ' + JSON.stringify(init));
	return fetchOk('/profile/', init)
		.then((response) => {debug('createProfile, response ok.');return response;})
		.catch((error) => {debug('createProfile, fetch, caught err.'); throw error;});

};

export const getProfiles = () => {
	debug('getProfiles, called.');

	let init = {
		...coreInit,
		method:			'GET'
	};

	debug('getProfiles, init is: ' + JSON.stringify(init));
	return fetchOk('/profiles', init)
		.then((response) => {debug('getProfiles, response ok.');return response;})
		.catch((error) => {debug('getProfiles, fetch, caught err.'); throw error;});

};

export const getProfile = (id) => {
	debug('getProfile, called.');

	let init = {
		...coreInit,
		method:			'GET'
	};

	debug('getProfile, init is: ' + JSON.stringify(init));
	return fetchOk('/profile/' + id, init)
		.then((response) => {debug('getProfile, response ok.');return response;})
		.catch((error) => {debug('getProfile, fetch, caught err.'); throw error;});

};

export const updateProfile = (id, givenName, familyName, email, zh) => {
	debug('updateProfile, called.');
	debug('updateProfile, adding: ' + id + ', ' + givenName + ', ' + familyName + ', ' + email + ', ' + zh);
	const formData = new FormData();
	formData.append('givenName', givenName);
	formData.append('familyName', familyName);
	formData.append('email', email);
	formData.append('zh', zh);

	let init = {
		credentials:	'same-origin',
		method:			'PUT',
		body:			formData
	};

	debug('updateProfile, init is: ' + JSON.stringify(init));
	return fetchOk('/profile/' + id, init)
		.then((response) => {debug('updateProfile, response ok.');return response;})
		.catch((error) => {debug('updateProfile, fetch, caught err.'); throw error;});

};

export const updateSub = (id, summarySub) => {
	debug('updateSub, called.');
	debug('updateSub, adding: ' + id + ', ' + summarySub);
	const formData = new FormData();
	formData.append('summarySub', summarySub);

	let init = {
		credentials:	'same-origin',
		method:			'PUT',
		body:			formData
	};

	debug('updateSub, init is: ' + JSON.stringify(init));
	return fetchOk('/profile/sub/' + id, init)
		.then((response) => {debug('updateSub, response ok.');return response;})
		.catch((error) => {debug('updateSub, fetch, caught err.'); throw error;});

};

export const updateRole = (id, role) => {
	debug('updateRole, called.');
	debug('updateRole, adding: ' + id + ', ' + role);
	const formData = new FormData();
	formData.append('role', role);

	let init = {
		credentials:	'same-origin',
		method:			'PUT',
		body:			formData
	};

	debug('updateRole, init is: ' + JSON.stringify(init));
	return fetchOk('/profile/role/' + id, init)
		.then((response) => {debug('updateRole, response ok.');return response;})
		.catch((error) => {debug('updateRole, fetch, caught err.'); throw error;});

};

export const deleteProfile = (id) => {
	debug('deleteProfile, called.');

	let init = {
		...coreInit,
		method:			'DELETE',
		body:			JSON.stringify({'id': id})
	};

	debug('deleteProfile, init is: ' + JSON.stringify(init));
	return fetchOk('/profile/' + id, init)
		.then((response) => {debug('deleteProfile, response ok.');return response;})
		.catch((error) => {debug('deleteProfile, fetch, caught err.'); throw error;});

};
