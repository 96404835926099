/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import UpdateTopicView from './UpdateTopicView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('UpdateTopic');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		topic:		state.topic.topic
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		updateTopic: (id, name, url) => {dispatch(actions.updateTopic(id, name, url));}
	};
};

const UpdateTopic = connect(mapStateToProps, mapDispatchToProps)(UpdateTopicView);

export default UpdateTopic;
