/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import * as index from '../index';
import * as actions from './action_types';
import * as requests from '../requests';

export const getArticles = (newOnly) => ({ type: actions.GET_ARTICLES, promise: requests.getArticles(newOnly) });
export const getArticle = (id) => ({ type: actions.GET_ARTICLE, promise: requests.getArticle(id) });
export const updateArticle = (id, title, author, url, date, content, summary, topics, rating, source, sourceUrl, newOnly) => ({ type: actions.UPDATE_ARTICLE, promise: requests.updateArticle(id, title, author, url, date, content, summary, topics, rating, source, sourceUrl), meta: {onSuccess: (newOnly) => index.store.dispatch(getArticles(newOnly))}});
export const deleteArticle = (id) => ({ type: actions.DELETE_ARTICLE, promise: requests.deleteArticle(id, newOnly), meta: {onSuccess: (newOnly) => index.store.dispatch(getArticles(newOnly))}});
