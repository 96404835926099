/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import Debug from 'debug';

let debug = Debug('AlertToast');

export default class AlertToast extends Component {

  	render () {
    	debug('render, called.');

		return <div  id="alert-toast-container" className="toast-container position-absolute top-0 end-0 p-3">
			<div id="alert-toast" className="toast align-items-center text-white bg-danger bg-gradient border-0" role="alert" aria-live="assertive" aria-atomic="true">
				<div className="d-flex">
					<div className="toast-body"><i className="bi bi-exclamation-triangle-fill">&nbsp;</i>{this.props.message}</div>
			    	<button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
			  	</div>
			</div>
		</div>;
	}
};
