/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import * as index from '../index';
import * as actions from './action_types';
import * as requests from '../requests';

export const createProfile = (givenName, familyName, email, zh) => ({ type: actions.CREATE_PROFILE, promise: requests.createProfile(givenName, familyName, email, zh), meta: {onSuccess: () => index.store.dispatch(getProfiles())}});
export const getProfiles = () => ({ type: actions.GET_PROFILES, promise: requests.getProfiles() });
export const getProfile = (id) => ({ type: actions.GET_PROFILE, promise: requests.getProfile(id) });
export const updateProfile = (id, givenName, familyName, email, zh) => ({ type: actions.UPDATE_PROFILE, promise: requests.updateProfile(id, givenName, familyName, email, zh), meta: {onSuccess: () => index.store.dispatch(getProfiles())}});
export const updateSub = (id, summarySub) => ({ type: actions.UPDATE_SUB, promise: requests.updateSub(id, summarySub)});
export const updateRole = (id, role) => ({ type: actions.UPDATE_ROLE, promise: requests.updateRole(id, role), meta: {onSuccess: () => index.store.dispatch(getProfiles())}});
export const deleteProfile = (id) => ({ type: actions.DELETE_PROFILE, promise: requests.deleteProfile(id), meta: {onSuccess: () => index.store.dispatch(getProfiles())}});
