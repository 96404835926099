/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React from 'react';
import {Route, Navigate} from 'react-router-dom';
import Debug from 'debug';

let debug = Debug('AuthRoute');

function authenticateRoute() {
	debug('authenticateRoute, called');
	let xhttp = new XMLHttpRequest();

	xhttp.open('GET', '/authenticate', false);
	xhttp.send();

	return (xhttp.status == 200);
}

const AuthRoute = ({ children}) => {
	return authenticateRoute()
		? children
		: <Navigate to={{ pathname: '/login'}} state={{ from: children.location}} replace />;
};

export default AuthRoute;
