/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import * as index from '../index';
import * as actions from './action_types';
import * as requests from '../requests';

export const runTest = () => ({ type: actions.RUN_TEST, promise: requests.runTest()});
export const emailTest = (email) => ({ type: actions.EMAIL_TEST, promise: requests.emailTest(email)});
