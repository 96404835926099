/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import TestFormView from './TestFormView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('TestForm');


const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		runTest: () => {dispatch(actions.runTest());},
		emailTest: (email) => {dispatch(actions.emailTest(email));}
	};
};

const TestForm = connect(null, mapDispatchToProps)(TestFormView);

export default TestForm;
