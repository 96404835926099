/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import MainMenuView from './MainMenuView';
import * as actions from '../actions';
import Debug from 'debug';

let debug = Debug('MainMenu');


const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		logout: () => {dispatch(actions.logout());}
	};
};

const MainMenu = connect(null, mapDispatchToProps)(MainMenuView);

export default MainMenu;
