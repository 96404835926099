/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import Debug from 'debug';
import Main from '../Main';
import FeedForm from './forms/FeedForm';
import TopicForm from './forms/TopicForm';
import UserForm from './forms/UserForm';
import TestForm from './forms/TestForm';
import ErrorBoundary from '../shared/ErrorBoundary';
import { ADMIN } from '../shared/constants';

let debug = Debug('SettingsView');

class SettingsView extends Component {

	renderAdminButtons() {
		debug('renderAdminButtons, called.');
		if (this.props.user.role === ADMIN) {
			return <>
				<button className="nav-link" id="nav-topic-tab" data-bs-toggle="tab" data-bs-target="#nav-user" type="button" role="tab" aria-controls="nav-user" aria-selected="false">Users</button>
				<button className="nav-link" id="nav-topic-tab" data-bs-toggle="tab" data-bs-target="#nav-test" type="button" role="tab" aria-controls="nav-test" aria-selected="false">Tests</button>
			</>;
		} else {
			return null;
		}
	}


	renderAdminTabs() {
		debug('renderAdminTabs, called.');
		if (this.props.user.role === ADMIN) {
			return <>
			<div className="tab-pane fade" id="nav-user" role="tabpanel" aria-labelledby="nav-topic-tab" tabIndex="0">
				<div className="container">
					<div className="row align-items-start">
						<div className="col-md-3"></div>
						<div className="col-md-6">
							<ErrorBoundary><UserForm /></ErrorBoundary>
						</div>
						<div className="col-md-3"></div>
					</div>
				</div>
			</div>
			<div className="tab-pane fade" id="nav-test" role="tabpanel" aria-labelledby="nav-topic-tab" tabIndex="0">
				<div className="container">
					<div className="row align-items-start">
						<div className="col-md-3"></div>
						<div className="col-md-6">
							<ErrorBoundary><TestForm /></ErrorBoundary>
						</div>
						<div className="col-md-3"></div>
					</div>
				</div>
			</div>
			</>;
		} else {
			return null;
		}
	}

	render () {
		debug('render, called.');

		return 	<Main>
			<h4>Settings</h4>
			<nav>
				<div className="nav nav-tabs" id="nav-tab" role="tablist">
					<button className="nav-link active" id="nav-feed-tab" data-bs-toggle="tab" data-bs-target="#nav-feed" type="button" role="tab" aria-controls="nav-feed" aria-selected="true">Feeds</button>
					<button className="nav-link" id="nav-topic-tab" data-bs-toggle="tab" data-bs-target="#nav-topic" type="button" role="tab" aria-controls="nav-topic" aria-selected="false">Topics</button>
					<ErrorBoundary>{this.renderAdminButtons()}</ErrorBoundary>
				</div>
			</nav>
			<div className="tab-content" id="nav-tabContent">

				<div className="tab-pane fade show active" id="nav-feed" role="tabpanel" aria-labelledby="nav-feed-tab" tabIndex="0">
					<div className="container">
						<div className="row align-items-start">
							<div className="col-md-3"></div>
							<div className="col-md-6">
								<ErrorBoundary><FeedForm/></ErrorBoundary>
							</div>
							<div className="col-md-3"></div>
						</div>
					</div>
				</div>

				<div className="tab-pane fade" id="nav-topic" role="tabpanel" aria-labelledby="nav-topic-tab" tabIndex="0">
					<div className="container">
						<div className="row align-items-start">
							<div className="col-md-3"></div>
							<div className="col-md-6">
								<ErrorBoundary><TopicForm/></ErrorBoundary>
							</div>
							<div className="col-md-3"></div>
						</div>
					</div>
				</div>

				<ErrorBoundary>{this.renderAdminTabs()}</ErrorBoundary>

			</div>
		</Main>;

	}
};



SettingsView.propTypes = {
	user:			PropTypes.object
};


export default SettingsView;
