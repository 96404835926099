/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import AddFeedView from './AddFeedView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('AddFeed');

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		createFeed: (name, url, zh_ok) => {dispatch(actions.createFeed(name, url, zh_ok));}
	};
};

const AddFeed = connect(null, mapDispatchToProps)(AddFeedView);

export default AddFeed;
