/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React from 'react';
import Debug from 'debug';

let debug = Debug('NotFound');

export default class NotFound extends React.Component {

	render() {
		debug('render, called.');

		return 	<div className="container-fluid">
			<div className="row hoa-content">
				<div className="col-md-4"></div>
				<div className="col-md-4">
					<div className="row d-flex justify-content-center">
						<h1>404 Page Not Found!</h1>
						<a href='https://www.saicic.com/'>Return to the main page.</a>
					</div>
				</div>
				<div className="col-md-4"></div>
			</div>
			<div className="footer main-footer fixed-bottom bg-light">
				<div className="footer-content text-muted"><span className="glyphicon glyphicon-copyright-mark" aria-hidden="true"></span> 2023 SAIC IC</div>
			</div>
		</div>;
	}
}
