/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import { Action, combineReducers } from 'redux';
import auth_reducer from './auth_reducer';
import profile_reducer from './profile_reducer';
import article_reducer from './article_reducer';
import feed_reducer from './feed_reducer';
import topic_reducer from './topic_reducer';
import llm_reducer from './llm_reducer';
import test_reducer from './test_reducer';

import Debug from 'debug';

let debug = Debug('reducer');

const reducer = combineReducers({
	auth: 		auth_reducer,
	profile: 	profile_reducer,
	article: 	article_reducer,
	feed: 		feed_reducer,
	topic:		topic_reducer,
    llm:        llm_reducer,
	test:		test_reducer
});

export default reducer;
