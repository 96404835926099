/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import NewsView from './NewsView';
import * as actions from '../../actions';
import Debug from 'debug';

let debug = Debug('News');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		isworking:	state.article.isworking,
		articles:	state.article.articles,
		user:		state.auth.user
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		getArticles: (newOnly) => {dispatch(actions.getArticles(newOnly));},
		getArticle: (id) => {dispatch(actions.getArticle(id));},
		getAnalysis: (id) => {dispatch(actions.getAnalysis(id));}
	};
};

const News = connect(mapStateToProps, mapDispatchToProps)(NewsView);

export default News;
