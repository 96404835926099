/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../shared/ErrorBoundary';
import Debug from 'debug';

let debug = Debug('UpdateTopicView');

export class UpdateTopicView extends Component {

	handleUpdate() {
		debug('handleUpdate, called.');

		if (this.props.topic) {
	    	this.props.updateTopic(
				this.props.topic._id,
				document.querySelector('#update-title').value,
			);
		}
	}


	renderBody() {
		debug('renderBody, called.');
		if (this.props.topic) {
			return <div className="modal-body">
				<div className="mb-3">
					<label htmlFor="update-title" className="form-label">Title</label>
					<input className="form-control" id="update-title" defaultValue={this.props.topic.title} aria-label="topic title" placeholder="Topic title that will be used to find news of interest." required></input>
				</div>
			</div>;
		} else {
			return <div className="modal-body">
			</div>;
		}
	};


	render () {
		debug('render, called.');

		return <div className="modal fade" id="update-topic-modal" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="topic-title" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="topic-title">Topic Dialog</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<ErrorBoundary>{this.renderBody()}</ErrorBoundary>
					<div className="modal-footer">
						<button type="button" className="btn btn-outline-primary" onClick={this.handleUpdate.bind(this)} data-bs-dismiss="modal">Update</button>
						<button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>;
	}
}


UpdateTopicView.propTypes = {
	topic:			PropTypes.object,
	updateTopic:	PropTypes.func
};


export default UpdateTopicView;
