/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import fetch from 'cross-fetch';
import Debug from 'debug';

let debug = Debug('request_utils');

export const coreInit = {
	credentials:	'same-origin',
	headers:		{'content-type': 'application/json'}
};

export const fetchOk = (api, init) => fetch(api, init)
 	.then(res => {
		debug('fetchOk response (pre-json): ' + JSON.stringify(res));
		return res.ok ? res.json() : res.json().then(err => Promise.reject(err));
	});

export function appendObjectArrayToFormData(array, arrayName, formData) {
	array.forEach((obj, index) => {
		Object.keys(obj).forEach((key) => {
			if(key != '_id') {
				const value = obj[key];
				const fieldKey = arrayName + '[' + index + '][' + key + ']';
				formData.append(fieldKey, value);
			}
		});
	});
}
