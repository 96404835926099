/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../shared/ErrorBoundary';
import ReactStars from 'react-rating-stars-component';
import Debug from 'debug';
import moment from 'moment';

let debug = Debug('ArticleView');

export class ArticleView extends Component {
	rating = 0;

	shouldComponentUpdate() {
		debug('shouldComponentUpdate, called.');
		return this.props.isworking;
	}

	handleRatingChange(newRating) {
		debug('handleRatingChange, called.');
		this.rating = newRating;
	}

	handleUpdate() {
		debug('handleUpdate, called.');

    	this.props.updateArticle(this.props.article._id,
			this.props.article.title,
			this.props.article.author,
			this.props.article.url,
			this.props.article.date,
			this.props.article.content,
			this.props.article.summary,
			this.props.article.topics,
			this.rating,
			this.props.article.source,
			this.props.article.sourceUrl);
	}

	handleDelete() {
		debug('handleDelete, called.');

    	this.props.deleteArticle(this.props.article._id);
	}

    renderTopics() {
		debug('renderTopics, called.');
        if (this.props.article) {
            const presentTopics = this.props.article.topics.filter(topic => topic.present);
            return presentTopics.map(topic => topic.title).join(', ');
        } else {
            return null;
        }
    }

	renderHeader() {
		debug('renderHeader, called.');
		if (this.props.article) {
			return <div className="modal-header">
				<span>
					<h5 className="modal-title" id="article-title">{this.props.article.title} <a href={this.props.article.url} target='_blank'><i className="bi bi-box-arrow-up-right"></i></a></h5>
					<small className="text-body-secondary">{moment(this.props.article.date).format('MMM Do, YYYY')} from {this.props.article.source} by {this.props.article.author}</small>
				</span>
				<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>;
		} else {
			return <div className="modal-header">
				<span>
					<h5 className="modal-title" id="article-title">Getting article... <a href="#" target='_blank'><i className="bi bi-box-arrow-up-right"></i></a></h5>
				</span>
				<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>;
		}
	}

	renderBody() {
		debug('renderBody, called.');
		if (this.props.article) {
			return <div className="modal-body">
				<div className="row">
                    <div className="col-md-12 ms-auto">
                        <label className="form-label">Topics:</label> <small>{this.renderTopics()}</small>
                    </div>
					<div className="col-md-6 ms-auto">
						<label htmlFor="summary" className="form-label">Summary</label>
						<textarea className="form-control" id="summary" rows="10" value={this.props.article.summary} aria-label="summary" readOnly></textarea>
						<label htmlFor="summary" className="form-label">Summary Rating</label>
						<ReactStars key={this.props.article._id} count={5} onChange={this.handleRatingChange.bind(this)} emptyIcon={<i className='bi bi-star'></i>} halfIcon={<i className='bi bi-star-half'></i>} fullIcon={<i className='bi bi-star-fill'></i>} isHalf={false} value={this.props.article.rating} />
					</div>
					<div className="col-md-6 ms-auto">
						<label htmlFor="content" className="form-label">Content</label>
						<textarea className="form-control" id="content" rows="10" value={this.props.article.content} aria-label="summary" readOnly></textarea>
					</div>
				</div>
			</div>;
		} else {
			return <div className="modal-body">
				<div className="row">
					<div className="col-md-6 ms-auto">
						Loading...
					</div>
					<div className="col-md-6 ms-auto">

					</div>
				</div>
			</div>;
		}
	};

	renderFooter() {
		return <div className="modal-footer">

			<button type="button" onClick={this.handleDelete.bind(this)} className="btn btn-outline-danger" data-bs-dismiss="modal">Delete Article</button>
			<button type="button" onClick={this.handleUpdate.bind(this)} className="btn btn-outline-primary" data-bs-dismiss="modal">Rate Article</button>
			<button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button>
		</div>;
	};

	render () {
		debug('render, called.');

		return <div className="modal fade" id="article-modal" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="article-title" aria-hidden="true">
			<div className="modal-dialog modal-lg modal-dialog-centered">
				<div className="modal-content">
		      		<ErrorBoundary>{this.renderHeader()}</ErrorBoundary>
		      		<ErrorBoundary>{this.renderBody()}</ErrorBoundary>
		      		<ErrorBoundary>{this.renderFooter()}</ErrorBoundary>
		    	</div>
		  	</div>
		</div>;
	}
}

ArticleView.propTypes = {
	isworking:		PropTypes.bool,
	article:		PropTypes.object,
	updateArticle:	PropTypes.func,
	deleteArticle: 	PropTypes.func
};

export default ArticleView;
