/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal } from 'bootstrap';
import ErrorBoundary from '../../shared/ErrorBoundary';
import UpdateUser from '../modals/UpdateUser';
import DeleteUser from '../modals/DeleteUser';
import AddExtUser from '../modals/AddExtUser';
import UpdateExtUser from '../modals/UpdateExtUser';
import DeleteExtUser from '../modals/DeleteExtUser';
import { showMessage } from '../../shared/MessageToast';
import { Toast } from 'bootstrap';
import Debug from 'debug';

let debug = Debug('UserFormView');


class UserFormView extends Component {

	shouldComponentUpdate() {
		debug('shouldComponentUpdate, called.');
		return this.props.isworking;
	}


	componentDidMount() {
		debug('componentDidMount, called.');
		this.props.getProfiles();
	}


	buildUserList() {
		debug('buildUserList, called.');
		if (this.props.profiles) {
			return this.props.profiles.map(profile =>
				profile.ext ? null : <option value={profile._id} key={profile._id}>{profile.givenName} {profile.familyName}</option>
			);
		}
	}

	buildExternalUserList() {
		debug('buildExternalUserList, called.');
		if (this.props.profiles) {
			return this.props.profiles.map(profile =>
				profile.ext ? <option value={profile._id} key={profile._id}>{profile.givenName} {profile.familyName}</option> : null
			);
		}

	}

	handleUpdate() {
		debug('handleUpdate, called.');
		var userList = document.getElementById('user-list');

		if (userList.selectedIndex != -1) {
			this.props.getProfile(userList.options[userList.selectedIndex].value);

			const userModal = new Modal('#update-user-modal');
			userModal.show();
		} else {
			showMessage('You must select a user to update.');
		}
	}


	handleDelete() {
		debug('handleDelete, called.');
		var userList = document.getElementById('user-list');

		if (userList.selectedIndex != -1) {
			this.props.getProfile(userList.options[userList.selectedIndex].value);

			const userModal = new Modal('#delete-user-modal');
			userModal.show();
		} else {
			showMessage('You must select a user to delete.');
		}

	}

	handleExternalAdd() {
		debug('handleExternalAdd, called.');
		const extUserModal = new Modal('#add-ext-user-modal');
		extUserModal.show();
	}

	handleExternalUpdate() {
		debug('handleExternalUpdate, called.');
		var extUserList = document.getElementById('external-user-list');

		if (extUserList.selectedIndex != -1) {
			this.props.getProfile(extUserList.options[extUserList.selectedIndex].value);

			const extUserModal = new Modal('#update-ext-user-modal');
			extUserModal.show();
		} else {
			showMessage('You must select an external user to update.');
		}
	}

	handleExternalDelete() {
		debug('handleExternalDelete, called.');
		var extUserList = document.getElementById('external-user-list');

		if (extUserList.selectedIndex != -1) {
			this.props.getProfile(extUserList.options[extUserList.selectedIndex].value);

			const extUserModal = new Modal('#delete-ext-user-modal');
			extUserModal.show();
		} else {
			showMessage('You must select an external user to delete.');
		}
	}

  	render () {
    	debug('render, called.');

		return 	<form>
			<div>
				<div>
					<label htmlFor="user-list" className="form-label">SAIC USA Users</label>
					<select className="form-select" id="user-list" size="5" aria-label="User selection" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-classname="custom-tooltip" data-bs-title="A list of all the SAIC USA users.">
						<ErrorBoundary>{this.buildUserList()}</ErrorBoundary>
					</select>
				</div>
				<div>
					<div className="btn-group list-buttons" role="group">
						<button type="button" className="btn btn-primary" onClick={this.handleUpdate.bind(this)}><i className="bi bi-pencil-square"></i></button>
						<button type="button" className="btn btn-primary" onClick={this.handleDelete.bind(this)}><i className="bi bi-dash"></i></button>
					</div>
				</div>
				<div>
					<label htmlFor="external-user-list" className="form-label">External Users</label>
					<select className="form-select" id="external-user-list" size="5" aria-label="External user selection" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-classname="custom-tooltip" data-bs-title="A list of all the additional users.">
						<ErrorBoundary>{this.buildExternalUserList()}</ErrorBoundary>
					</select>
				</div>
				<div className="btn-group list-buttons" role="group">
					<button type="button" className="btn btn-primary" onClick={this.handleExternalAdd.bind(this)}><i className="bi bi-plus-lg"></i></button>
					<button type="button" className="btn btn-primary" onClick={this.handleExternalUpdate.bind(this)}><i className="bi bi-pencil-square"></i></button>
					<button type="button" className="btn btn-primary" onClick={this.handleExternalDelete.bind(this)}><i className="bi bi-dash"></i></button>
				</div>
				<UpdateUser/>
				<DeleteUser/>
				<AddExtUser/>
				<UpdateExtUser/>
				<DeleteExtUser/>
			</div>
		</form>;
	}
};

UserFormView.propTypes = {
	isworking:		PropTypes.bool,
	getProfile: 		PropTypes.func,
	getProfiles: 		PropTypes.func,
	profiles:			PropTypes.array
};

export default UserFormView;
