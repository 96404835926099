/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../shared/ErrorBoundary';
import ReactStars from 'react-rating-stars-component';
import Debug from 'debug';
import moment from 'moment';

let debug = Debug('DeleteUserView');

export class DeleteUserView extends Component {

	handleDelete() {
		this.props.deleteProfile(this.props.profile._id);
	}


	renderBody() {
		if (this.props.profile) {
			return <div className="modal-body">
				Ok to delete user: {this.props.profile.givenName} {this.props.profile.familyName}.<br/>
				Note: this only removes their record within this application.
			</div>;
		} else {
			return <div className="modal-body">
			</div>;
		}
	}


	render () {
		debug('render, called.');
		return <div className="modal fade" id="delete-user-modal" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="user-title" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="user-title">Delete User</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<ErrorBoundary>{this.renderBody()}</ErrorBoundary>
					<div className="modal-footer">
						<button type="button" className="btn btn-outline-danger" onClick={this.handleDelete.bind(this)} data-bs-dismiss="modal">Delete</button>
						<button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>;
	}
}


DeleteUserView.propTypes = {
	profile:			PropTypes.object,
	deleteProfile:		PropTypes.func
};


export default DeleteUserView;
