/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import ProfileView from './ProfileView';
import * as actions from '../../actions';
import Debug from 'debug';

let debug = Debug('Profile');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		user:		state.auth.user,
		profile:	state.profile.profile
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		getProfile: (id) => {dispatch(actions.getProfile(id));},
		updateSub: (id, summarySub) => {dispatch(actions.updateSub(id, summarySub));}
	};
};

const Profile = connect(mapStateToProps, mapDispatchToProps)(ProfileView);

export default Profile;
