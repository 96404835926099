/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import { Toast } from 'bootstrap';
import Debug from 'debug';

let debug = Debug('MessageToast');

export default class MessageToast extends Component {
	messageToast = null;

  	render () {
    	debug('render, called.');

		return <div  id="message-toast-container" className="toast-container position-absolute top-0 end-0 p-3">
			<div id="message-toast" className="toast align-items-center text-white bg-primary bg-gradient border-0" role="alert" aria-live="assertive" aria-atomic="true">
				<div className="d-flex">
					<div className="toast-body"><i className="bi bi-info-circle-fill">&nbsp;</i><span id="message"></span></div>
			    	<button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
			  	</div>
			</div>
		</div>;
	}
};

export function showMessage(message) {
	debug('showMessage, called.');
	let messageToast = document.getElementById('message-toast');
	messageToast.querySelector('#message').innerHTML = message;
	let toast = Toast.getOrCreateInstance(messageToast);
	toast.show();
}
