/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../shared/ErrorBoundary';
import { rfc5322 } from '../../shared/constants';
import Debug from 'debug';

let debug = Debug('UpdateUserView');

export class UpdateUserView extends Component {

	handleUpdate() {
		debug('handleUpdate, called.');

		if (this.props.profile) {
	    	this.props.updateRole(
				this.props.profile._id,
				document.querySelector('#update-role').value
			);

		}
	}


	renderBody() {
		debug('renderBody, called.');
		if (this.props.profile) {
			return <div className="modal-body">
				<div className="mb-3">
					<label htmlFor="update-first-name" className="form-label">First Name</label>
					<input className="form-control" id="update-first-name" defaultValue={this.props.profile.givenName} aria-label="users first name" placeholder="The users first name." disabled readOnly></input>
				</div>
				<div className="mb-3">
					<label htmlFor="update-family-name" className="form-label">Last Name</label>
					<input className="form-control" id="update-family-name" defaultValue={this.props.profile.familyName} aria-label="users last name" placeholder="The users last name." disabled readOnly></input>
				</div>
				<div className="mb-3">
					<label htmlFor="update-email" className="form-label">E-mail</label>
					<input type="email" className="form-control" id="update-email" defaultValue={this.props.profile.email} aria-label="users email" placeholder="The users email." disabled readOnly></input>
				</div>
				<div className="mb-3">
 					<label htmlFor="update-role" className="form-label">User Role</label>
 					<select className="form-select" id="update-role" defaultValue={this.props.profile.role} aria-label="User role selector">
 						<option value="READER">News Reader</option>
 						<option value="ADMIN">Administrator</option>
 					</select>
 				</div>
			</div>;
		} else {
			return <div className="modal-body">
			</div>;
		}
	};


	render () {
		debug('render, called.');

		return <div className="modal fade" id="update-user-modal" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="user-title" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="user-title">Update User</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<ErrorBoundary>{this.renderBody()}</ErrorBoundary>
					<div className="modal-footer">
						<button type="button" className="btn btn-outline-primary" onClick={this.handleUpdate.bind(this)} data-bs-dismiss="modal">Update</button>
						<button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>;
	}
}


UpdateUserView.propTypes = {
	profile:			PropTypes.object,
	updateRole:		PropTypes.func
};


export default UpdateUserView;
