/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import * as index from '../index';
import * as actions from './action_types';
import * as requests from '../requests';

export const createFeed = (name, url, zh_ok) => ({ type: actions.CREATE_FEED, promise: requests.createFeed(name, url, zh_ok), meta: {onSuccess: () => index.store.dispatch(getFeeds())}});
export const getFeeds = () => ({ type: actions.GET_FEEDS, promise: requests.getFeeds() });
export const getFeed = (id) => ({ type: actions.GET_FEED, promise: requests.getFeed(id) });
export const updateFeed = (id, name, url, zh_ok) => ({ type: actions.UPDATE_FEED, promise: requests.updateFeed(id, name, url, zh_ok), meta: {onSuccess: () => index.store.dispatch(getFeeds())}});
export const deleteFeed = (id) => ({ type: actions.DELETE_FEED, promise: requests.deleteFeed(id), meta: {onSuccess: () => index.store.dispatch(getFeeds())}});
