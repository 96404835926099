/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import UserFormView from './UserFormView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('UserForm');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		isworking:	state.profile.isworking,
		profiles:		state.profile.profiles
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		getProfiles: () => {dispatch(actions.getProfiles());},
		getProfile: (id) => {dispatch(actions.getProfile(id));},
		deleteProfile: (id) => {dispatch(actions.deleteProfile(id));}
	};
};

const UserForm = connect(mapStateToProps, mapDispatchToProps)(UserFormView);

export default UserForm;
