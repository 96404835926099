/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../shared/ErrorBoundary';
import Debug from 'debug';

let debug = Debug('AnalysisView');

export class AnalysisView extends Component {


	renderBody() {
		debug('renderBody, called.');
		debug('analysis: ' + this.props.analysis);
		if (this.props.analysis) {
			return <div className="modal-body display-linebreak">
				{this.props.analysis}
			</div>;
		} else {
			return <div className="modal-body display-linebreak">
				<p>Generating an analysis can take some time!</p><br />
				<div className="text-center">
					<div className="spinner-border text-primary" role="status">
						<span className="visually-hidden">Loading...</span>
					</div>
				</div>
			</div>;
		}
	};


	render () {
		debug('render, called.');

		return <div className="modal fade" id="analysis-modal" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="analysis-title" aria-hidden="true">
			<div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="analysis-title">Analysis</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
		      		<ErrorBoundary>{this.renderBody()}</ErrorBoundary>
					<div className="modal-footer">
						<button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button>
            		</div>
		    	</div>
		  	</div>
		</div>;
	}
}

AnalysisView.propTypes = {
	analysis:		PropTypes.string
};

export default AnalysisView;
