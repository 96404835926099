/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal } from 'bootstrap';
import ErrorBoundary from '../../shared/ErrorBoundary';
import AddFeed from '../modals/AddFeed';
import UpdateFeed from '../modals/UpdateFeed';
import DeleteFeed from '../modals/DeleteFeed';
import { showMessage } from '../../shared/MessageToast';
import { Toast } from 'bootstrap';
import Debug from 'debug';

let debug = Debug('FeedFormView');


class FeedFormView extends Component {

	shouldComponentUpdate() {
		debug('shouldComponentUpdate, called.');
		return this.props.isworking;
	}

	componentDidMount() {
		debug('componentDidMount, called.');
		this.props.getFeeds();
	}

	buildFeedList() {
		debug('buildFeedList, called.');
		return this.props.feeds.map(feed => <option value={feed._id} key={feed._id}>{feed.name}</option>);
	}

	handleAdd() {
		debug('handleAdd, called.');
		const feedModal = new Modal('#add-feed-modal');
		feedModal.show();
	}

	handleUpdate() {
		debug('handleUpdate, called.');
		var feedList = document.getElementById('feed-list');

		if (feedList.selectedIndex != -1) {
			this.props.getFeed(feedList.options[feedList.selectedIndex].value);

			const feedModal = new Modal('#update-feed-modal');
			feedModal.show();
		} else {
			showMessage('You must select a feed to update.');
		}
	}

	handleDelete() {
		debug('handleDelete, called.');
		var feedList = document.getElementById('feed-list');

		if (feedList.selectedIndex != -1) {
			this.props.getFeed(feedList.options[feedList.selectedIndex].value);

			const feedModal = new Modal('#delete-feed-modal');
			feedModal.show();
		} else {
			showMessage('You must select a feed to delete.');
		}

	}



  	render () {
    	debug('render, called.');

		return 	<form>
			<div>
				<div>
					<label htmlFor="feed-list" className="form-label">Current RSS feeds</label>
					<select className="form-select" id="feed-list" size="5" aria-label="RSS feed selection" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-classname="custom-tooltip" data-bs-title="A list of all the RSS sites.">
						<ErrorBoundary>{this.buildFeedList()}</ErrorBoundary>
					</select>
				</div>
				<div>
					<div className="btn-group list-buttons" role="group">
						<button type="button" className="btn btn-primary" onClick={this.handleAdd.bind(this)}><i className="bi bi-plus-lg"></i></button>
						<button type="button" className="btn btn-primary" onClick={this.handleUpdate.bind(this)}><i className="bi bi-pencil-square"></i></button>
						<button type="button" className="btn btn-primary" onClick={this.handleDelete.bind(this)}><i className="bi bi-dash"></i></button>
					</div>
				</div>
				<AddFeed/>
				<UpdateFeed/>
				<DeleteFeed/>
			</div>
		</form>;
	}
};

FeedFormView.propTypes = {
	isworking:		PropTypes.bool,
	getFeed: 		PropTypes.func,
	getFeeds: 		PropTypes.func,
	feeds:			PropTypes.array
};

export default FeedFormView;
