/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import AddTopicView from './AddTopicView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('AddTopic');


const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		createTopic: (title) => {dispatch(actions.createTopic(title));}
	};
};

const AddTopic = connect(null, mapDispatchToProps)(AddTopicView);

export default AddTopic;
