/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import * as index from '../index';
import * as actions from './action_types';
import * as requests from '../requests';

export const createTopic = (title) => ({ type: actions.CREATE_TOPIC, promise: requests.createTopic(title), meta: {onSuccess: () => index.store.dispatch(getTopics())}});
export const getTopics = () => ({ type: actions.GET_TOPICS, promise: requests.getTopics() });
export const getTopic = (id) => ({ type: actions.GET_TOPIC, promise: requests.getTopic(id) });
export const updateTopic = (id, title) => ({ type: actions.UPDATE_TOPIC, promise: requests.updateTopic(id, title), meta: {onSuccess: () => index.store.dispatch(getTopics())}});
export const deleteTopic = (id) => ({ type: actions.DELETE_TOPIC, promise: requests.deleteTopic(id), meta: {onSuccess: () => index.store.dispatch(getTopics())}});
