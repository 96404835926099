/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import {NavLink, Navigate} from 'react-router-dom';
import Debug from 'debug';

let debug = Debug('MainMenuView');

class MainMenuView extends Component {


	render () {
		debug('render, called.');

		return 	<nav className="navbar navbar-expand-sm shadow-sm fixed-top navbar-light bg-light">
			<div className="container-fluid">
				<a className="navbar-brand" href="#">
					<img alt="SAIC Logo" src="images/saic.png" height="30" width="30" />
				</a>

				<button type="button" className="navbar-toggler me-auto"  data-bs-toggle="collapse" data-bs-target="#links" aria-expanded="false" aria-controls="navbarResponsive"  aria-label="Toggle navigation">
					<span className="bi bi-list"></span>
				</button>

				<button type="button" className="navbar-toggler ms-auto"  data-bs-toggle="collapse" data-bs-target="#account" aria-expanded="false" aria-controls="navbarResponsive" aria-label="Toggle navigation">
					<span className="bi bi-person"></span>
				</button>

				<div id="links" className="collapse navbar-collapse">
    				<ul className="navbar-nav me-auto">
    					<li className="nav-item">
    						<NavLink className="nav-link" to="/news">News</NavLink>
    					</li>
    				</ul>
    			</div>

				<div id="account" className="collapse navbar-collapse">
					<ul className="navbar-nav ms-auto">
						<li className="nav-item">
							<NavLink className="nav-link" to="/profile">Profile</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className="nav-link" to="/settings">Settings</NavLink>
						</li>
						<li className="dropdown-divider"></li>
						<li className="nav-item">
							<a className="nav-link" href="/" onClick={this.props.logout}>Logout</a>
						</li>
					</ul>
				</div>

			</div>
		</nav>;

	}
};

MainMenuView.propTypes = {
	logout:		PropTypes.func
};

export default MainMenuView;
