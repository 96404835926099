/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import UpdateUserView from './UpdateUserView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('UpdateUser');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		profile:		state.profile.profile
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		updateRole: (id, role) => {dispatch(actions.updateRole(id, role));}
	};
};

const UpdateUser = connect(mapStateToProps, mapDispatchToProps)(UpdateUserView);

export default UpdateUser;
