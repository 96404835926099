/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../shared/ErrorBoundary';
import { rfc5322 } from '../../shared/constants';
import Debug from 'debug';

let debug = Debug('AddExtUserView');

export class AddExtUserView extends Component {

	handleAdd() {
		debug('handleAdd, called.');
    	this.props.createProfile(
			document.querySelector('#add-ext-first-name').value,
			document.querySelector('#add-ext-family-name').value,
			document.querySelector('#add-ext-email').value,
			document.querySelector('#add-ext-zh').checked
		);

		document.querySelector('#add-ext-first-name').value = '';
		document.querySelector('#add-ext-family-name').value = '';
		document.querySelector('#add-ext-email').value = '';
		document.querySelector('#add-ext-zh').checked = false;
	}


	renderBody() {
		debug('renderBody, called.');

		return <div className="modal-body">
			<div className="mb-3">
				<label htmlFor="add-ext-first-name" className="form-label">First Name</label>
				<input className="form-control" id="add-ext-first-name" aria-label="users first name" placeholder="The users first name."></input>
			</div>
			<div className="mb-3">
				<label htmlFor="add-ext-family-name" className="form-label">Last Name</label>
				<input className="form-control" id="add-ext-family-name" aria-label="users last name" placeholder="The users last name."></input>
			</div>
			<div className="mb-3">
				<label htmlFor="add-ext-email" className="form-label">E-mail</label>
				<input type="email" className="form-control" id="add-ext-email" aria-label="users email" placeholder="The users email."></input>
			</div>
			<div className="mb-3">
				<input className="form-check-input" type="checkbox" id="add-ext-zh"/>
				&nbsp;<label htmlFor="add-ext-zh" className="form-label">Account is in China?</label>
			</div>
		</div>;

	};


	render () {
		debug('render, called.');

		return <div className="modal fade" id="add-ext-user-modal" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="user-title" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="user-title">Add External User</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<ErrorBoundary>{this.renderBody()}</ErrorBoundary>
					<div className="modal-footer">
						<button type="button" className="btn btn-outline-primary" onClick={this.handleAdd.bind(this)} data-bs-dismiss="modal">Add</button>
						<button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>;
	}
}


AddExtUserView.propTypes = {
	profile:			PropTypes.object,
	createProfile:		PropTypes.func
};


export default AddExtUserView;
