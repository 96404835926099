/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import fetch from 'cross-fetch';
import { coreInit, fetchOk, appendObjectArrayToFormData } from './request_utils';
import Debug from 'debug';

let debug = Debug('topic_requests');

export const createTopic = (title) => {
	debug('createTopic, called.');
	debug('createTopic, adding: ' + title);
	const formData = new FormData();
	formData.append('title', title);

	let init = {
		credentials:	'same-origin',
		method:			'POST',
		body:			formData
	};

	debug('createTopic, init is: ' + JSON.stringify(init));
	return fetchOk('/topic', init)
		.then((response) => {debug('createTopic, response ok.');return response;})
		.catch((error) => {debug('createTopic, fetch, caught err.'); throw error;});

};

export const getTopics = () => {
	debug('getTopics, called.');

	let init = {
		...coreInit,
		method:			'GET'
	};

	debug('getTopics, init is: ' + JSON.stringify(init));
	return fetchOk('/topics', init)
		.then((response) => {debug('getTopics, response ok.');return response;})
		.catch((error) => {debug('getTopics, fetch, caught err.'); throw error;});

};

export const getTopic = (id) => {
	debug('getTopic, called.');

	let init = {
		...coreInit,
		method:			'GET'
	};

	debug('getTopic, init is: ' + JSON.stringify(init));
	return fetchOk('/topic/' + id, init)
		.then((response) => {debug('getTopic, response ok.');return response;})
		.catch((error) => {debug('getTopic, fetch, caught err.'); throw error;});

};

export const updateTopic = (id, title) => {
	debug('updateTopic, called.');
	debug('updateTopic, adding: ' + id + ', ' + title);
	const formData = new FormData();
	formData.append('title', title);

	let init = {
		credentials:	'same-origin',
		method:			'PUT',
		body:			formData
	};

	debug('updateTopic, init is: ' + JSON.stringify(init));
	return fetchOk('/topic/' + id, init)
		.then((response) => {debug('updateTopic, response ok.');return response;})
		.catch((error) => {debug('updateTopic, fetch, caught err.'); throw error;});

};

export const deleteTopic = (id) => {
	debug('deleteTopic, called.');

	let init = {
		...coreInit,
		method:			'DELETE',
		body:			JSON.stringify({'id': id})
	};

	debug('deleteTopic, init is: ' + JSON.stringify(init));
	return fetchOk('/topic/' + id, init)
		.then((response) => {debug('deleteTopic, response ok.');return response;})
		.catch((error) => {debug('deleteTopic, fetch, caught err.'); throw error;});

};
